import { createTheme, ThemeProvider } from '@mui/material/styles';

export const theme = createTheme({
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            color: "#2E3B55"
          },
        },
      },
    },
  });