import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TablePage from './pages/tablePage';
import DetailPage from './pages/detailPage';
import HistoryPage from './pages/historyPage';
import { signInWithRedirect, getCurrentUser, fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

const provider = { custom: 'SamlProviderv1prod' };

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fullName, setFullName] = useState('');


  useEffect(() => {
    checkUserAuthentication();
  }, []);

  const getUserFullName = async () => {
    try {
      const user = await getCurrentUser();
      const idToken = (await fetchAuthSession()).tokens.idToken;
      const payload = idToken.payload;
      const firstName = payload['custom:firstname'];
      const lastName = payload['custom:lastname'];
      return `${firstName} ${lastName}`;
    } catch (error) {
      console.error('Error getting user full name:', error);
      return '';
    }
  };
  

  const checkUserAuthentication = async () => {
    try {
      const user = await getCurrentUser();
      if (user) {
        setIsAuthenticated(true);
      } else {
        signInWithRedirect({ provider });
      }
    } catch (error) {
      console.log("User not signed in yet");
      signInWithRedirect({ provider });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserFullName();
  }, []);

  const fetchUserFullName = async () => {
    const name = await getUserFullName();
    setFullName(name);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? (
    <Router>
      <Routes>
        <Route path="/" element={<TablePage fullName={fullName} />} />
        <Route path="/details/:id" element={<DetailPage />} />
        <Route path="/history" element={<HistoryPage />} />
      </Routes>
    </Router>
  ) : null;
};

export default App;