import React,{ useEffect} from 'react';
import { useParams, useLocation,useNavigate, Navigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Avatar, Card, CardHeader,CardContent, Divider, Chip,ThemeProvider, CircularProgress,Pagination,TablePagination} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {isDateProblematic,getStatusChipProps,getNumberOfDaysPastExpiration,stringAvatar,processCSV} from '../helpers/utils'
import {theme} from '../helpers/colors'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {generateClient} from 'aws-amplify/api';
import { first } from 'lodash';


const fetchHistoryQuery = { 
  query : `
    query FetchHistory {
      fetchHistory {
        code
        historyData{
          dateOfChange
          listOfChanges{
            email
            privilegeName
            userId
          }
        }
      }
    }
  `,
  authMode: 'userPool'
};

const HistoryPage = () => {
  const location = useLocation();
  const item = location.state?.item;
  const navigate = useNavigate();
  const clientGraphQL = generateClient();
  const [open, setOpen] = React.useState(false);
  const [historyData, sethistoryData] = React.useState([]);
  const [loading, setLoading] = React.useState(false); // Add a loading state
  const [currentPage, setCurrentPage] = React.useState(1); // Current page number
  const [currentPageHeaderVersion,setCurrentPageHeaderVersion] = React.useState(0);
  const [itemsPerPage,setItemsPerPage] = React.useState(10); // Number of items to display per page
  const [indexOfLastItem,setIndexOfLastItem] = React.useState(1);
  const [indexOfFirstItem ,setIndexOfFirstItem] =  React.useState(1);
  const [currentItems,setCurrentItems] = React.useState([]);
  const [totalPages,setTotalPages] = React.useState(1);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  async function fetchHistory() {
    setLoading(true);
    try {
      const result = await clientGraphQL.graphql(fetchHistoryQuery);
      console.log('Query result:', result);
      sethistoryData(result.data.fetchHistory.historyData);
    } catch (error) {
      console.error('Error calling query:', error);
    } finally {
      setLoading(false); // Set loading to false when data is fetched or error occurs
    }
  }

  useEffect(() => {
    fetchHistory()
  }, []);

  useEffect(() => {
    console.log("history:",historyData)
    setTotalPages(Math.ceil(historyData.length / itemsPerPage));
    const lastItem =  currentPage * itemsPerPage;
    setIndexOfLastItem(lastItem);
    const firstItem = lastItem - itemsPerPage;
    setIndexOfFirstItem(firstItem);
    setCurrentItems(historyData.slice(firstItem, lastItem));
  }, [historyData]);

  useEffect(() => {
    const lastItem =  currentPage * itemsPerPage;
    setIndexOfLastItem(lastItem);
    const firstItem = lastItem - itemsPerPage;
    setIndexOfFirstItem(firstItem);
    setCurrentItems(historyData.slice(firstItem,lastItem));
  }, [currentPage,itemsPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setCurrentPageHeaderVersion(value-1);
  };

  const handlePageChangeHeaderVersion = (event, value) => {
    setCurrentPage(value+1);
    setCurrentPageHeaderVersion(value);
  };


  const handleChangeitemsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <>
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            History
          </Typography>
        </Toolbar>
      </AppBar>
      </ThemeProvider>
      <div>
      {loading ? ( // Conditionally render the spinner
        <CircularProgress /> 
      ) : (
        <div>
       <TablePagination
          component="div"
          count={historyData.length}
          page={currentPageHeaderVersion}
          onPageChange={handlePageChangeHeaderVersion}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={handleChangeitemsPerPage}
        />
       {currentItems.map((result, index) => (
  <div style={{marginLeft:"auto",marginRight:"auto",maxWidth:"80%"}}><Accordion defaultExpanded={index === 0} sx={{marginLeft: "auto", marginRight: "auto", marginTop: "3%" }}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`panel${index}-content`}
      id={`panel${index}-header`}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Typography>{result.dateOfChange}</Typography>
        <Typography>{result.listOfChanges.length} entries</Typography>
      </div>
    </AccordionSummary>
    <AccordionDetails>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ width: '33.33%' }}>UserId</th>
            <th style={{ width: '33.33%' }}>Email</th>
            <th style={{ width: '33.33%' }}>Privilege Name</th>
          </tr>
        </thead>
        <tbody>
          {result.listOfChanges.map((item, index) => (
            <tr key={index}>
              <td style={{ width: '33.33%', color: 'hsl(0, 25%, 30%)', borderBottom: '1px solid #ddd', padding: '8px' }} className='list_userid'>{item.userId}</td>
              <td style={{ width: '33.33%', color: 'hsl(20, 50%, 30%)', borderBottom: '1px solid #ddd', padding: '8px' }} className='list_email'>{item.email}</td>
              <td style={{ width: '33.33%', color: 'hsl(90, 30%, 30%)', borderBottom: '1px solid #ddd', padding: '8px' }} className='list_privilege'>{item.privilegeName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </AccordionDetails>
  </Accordion>
  </div>
))}
        </div>
        )}
     </div>
     <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </div>
    </>
  );
};

export default HistoryPage;