import React from 'react';
import { useParams, useLocation,useNavigate, Navigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Avatar, Card, CardHeader,CardContent, Divider, Chip,ThemeProvider, Button} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import {isDateProblematic,getStatusChipProps,getNumberOfDaysPastExpiration,stringAvatar,processCSV} from '../helpers/utils'
import {theme} from '../helpers/colors'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {generateClient} from 'aws-amplify/api';


const DetailPage = () => {
  const location = useLocation();
  const item = location.state?.item;
  const navigate = useNavigate();
  const clientGraphQL = generateClient();
  const [open, setOpen] = React.useState(false);

  if (!item) {
    // Redirect to the home page or show an error message
    return <Navigate to="/" />;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            User Details
          </Typography>
        </Toolbar>
      </AppBar>
      </ThemeProvider>
      <div>
      <Card sx={(theme) => ({
            maxWidth: "80%",
            marginLeft:"auto",
            marginRight:"auto",
            marginTop:"3%"
               })}>
      <CardHeader
        avatar={
          <Avatar {...stringAvatar(item.name)} />
        }
        title={item.name}
        action= {item.status && (
          <div>

        <Button
          size="large"
          aria-label="deactivate"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          variant='outlined'
          onClick={() => {
            processCSV([item.userId],clientGraphQL)
            setOpen(true);
          }}
          startIcon={<DoDisturbIcon />}
        >
          Deactivate
        </Button>
            </div>
            )}
      />
      <Divider variant='middle'/>
      <CardContent>
        <p><span class=''>Privilege:</span> {item.privilegeName}</p>
        <p><span>Privilege ID:</span> {item.privilegeId}</p>
        <p>Role ID: {item.roleId}</p>
        <p>Status: <Chip {...getStatusChipProps(item.status, item.last_login)} /></p>
        <p>Last login: {item.last_login?item.last_login:"none"} <span style={{color:"red"}}>{isDateProblematic(item.last_login)?"("+getNumberOfDaysPastExpiration(item.last_login)+" days)":""}</span></p>
        <p>Privilege deactivated at : {item.lastDeactivated?item.lastDeactivated:"none"}</p>
        <p>Last login before deactivation: {item.last_login_deactivation?item.last_login_deactivation:"none"} <span style={{color:"red"}}>{isDateProblematic(item.last_login_deactivation,item.lastDeactivated)?"("+getNumberOfDaysPastExpiration(item.last_login_deactivation,item.lastDeactivated)+" days)":""}</span></p>
      </CardContent>
      </Card>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          The user will be deleted in few minutes. Please wait and refresh the page to check the staus.
        </Alert>
      </Snackbar>
     </div>
    </>
  );
};

export default DetailPage;